import dayjs from 'dayjs';
import {camelizeKeys} from 'humps';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import {TasksApi} from 'api';
import {useFilterContext} from 'modules/Tasks/components/Filters';
import {SortOrder} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {fetchAllWithGenerator} from 'shared/helpers/axios'; // Importing the new fetch function
import {IssueModelDTO, IssueModelRawDTO, TaskStatus} from 'shared/models/task';

const filterClosedStatus = (issue: IssueModelDTO) => issue.status !== TaskStatus.closed;

export function useFetchDailiesIssues() {
  const {projectId} = useParams<{projectId: string}>();
  const {queryParams} = useFilterContext();

  const selectedDay = dayjs(queryParams.schedEndFirst).toISOString();
  const queryKey = [QUERY_CACHE_KEYS.dailiesIssues, projectId, selectedDay];

  const fetcher = async () => {
    return fetchAllWithGenerator<IssueModelRawDTO>({
      request: (offset, limit) =>
        TasksApi.getTaskIssues(projectId, {
          rangeParams: {
            offset,
            limit,
          },
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
          },
        }),
      initialTake: 500,
    });
  };

  const {data: res, isLoading} = useQuery(queryKey, fetcher);

  const issues = res ? (camelizeKeys(res) as IssueModelDTO[]).filter(filterClosedStatus) : [];
  return {issues, isLoading};
}
