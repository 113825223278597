import * as Sentry from '@sentry/react';
import {TimeZone} from '@vvo/tzdb';
import {Field, FieldProps, Form, useFormikContext} from 'formik';
import {TFunction} from 'i18next';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';

import {TimezoneApi} from 'api';
import {
  Button,
  FormControl,
  Geocoder,
  Icon,
  OptionType,
  Permission,
  SkeletonFieldPreloader,
  TimePicker,
} from 'shared/components';
import {AsyncProjectSubcontractorSelect} from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import {isObject} from 'shared/helpers/common';
import {useAnalyticsService} from 'shared/hooks';
import {useMount, useOutsideClick} from 'shared/hooks/core';
import {useBrowserTimezone} from 'shared/hooks/useBrowserTimezone';
import {useProject} from 'shared/hooks/useProject';
import {CompanyOrgs} from 'shared/models/company';
import {ProjectModel} from 'shared/models/project';

type FormProps = {
  onDelete: () => void;
  isNewProject?: boolean;
  disabled?: boolean;
};

const getCountryOptions = (t: TFunction): OptionType[] => [
  {label: t('project:form.country.options.usa', 'USA'), value: 'USA'},
  {label: t('project:form.country.options.brazil', 'Brazil'), value: 'Brazil'},
  {label: t('project:form.country.options.japan', 'Japan'), value: 'Japan'},
  {label: t('project:form.country.options.singapore', 'Singapore'), value: 'Singapore'},
  {label: t('project:form.country.options.saudi_arabia', 'Saudi Arabia'), value: 'Saudi Arabia'},
];

const prettifyTimezone = (str: string): string => str.replace('_', ' ');

const mappingTimezoneToCountry = {
  USA: 'US',
  Brazil: 'BR',
  Japan: 'JP',
  Singapore: 'SG',
  SaudiArabia: 'SA',
};

const ProjectForm: FC<FormProps> = ({disabled, onDelete, isNewProject}) => {
  const {values, setFieldValue, resetForm, setValues} = useFormikContext<Partial<ProjectModel>>();
  const location = useLocation<{afterCloningProject: boolean}>();
  const [timeZonesList, setTimeZonesList] = useState<TimeZone[]>();
  const localTimezone = useBrowserTimezone();
  const {id} = useParams<{id: string}>();
  const {loading, project} = useProject(id);
  const tooltipRef = useRef();
  const projectNameInputRef = useRef<HTMLInputElement>(null);
  const [tooltipTimeZoneVisible, setTooltipTimeZoneVisible] = useState(false);
  const {
    mixpanel: {
      events: {
        project: {formFields: mixpanelEvents},
      },
      ...mixpanel
    },
  } = useAnalyticsService();
  const mixpanelMeta = useMemo(() => (project ? {'Project Name': project.name} : {}), [project]);
  const {t} = useTranslation('project');

  const onSelectCompany = (fieldName: string, companyOrg: CompanyOrgs | string | null) => {
    setFieldValue(fieldName, isObject(companyOrg) ? companyOrg.id : companyOrg);
  };

  useEffect(() => {
    if (location.state?.afterCloningProject && !loading) {
      // set cursor to the beginning of the input
      projectNameInputRef.current?.setSelectionRange(0, 0);
      projectNameInputRef.current?.focus();
    }
  }, [location.state, loading]);

  const getTimezoneByCoordinates = async (longitude: number, latitude: number) => {
    if (timeZonesList.length === 0) {
      return;
    }

    try {
      const lookupResult = await TimezoneApi.timezoneLookup(longitude, latitude);
      const properTimezone = timeZonesList?.find(
        (timezone) => timezone.name === lookupResult.timezone || timezone.group.includes(lookupResult.timezone),
      );

      if (properTimezone) {
        setFieldValue('timezone', prettifyTimezone(properTimezone.name));
      }
    } catch (error) {
      toast.error(t('form.timezone.errors.timezones_list_error'));
      Sentry.captureException(error, {tags: {context: 'ProjectForm', name: 'getTimezoneByCoordinates'}});
    }
  };

  const timezonesOptions = useMemo(() => {
    if (values?.country && timeZonesList) {
      return timeZonesList
        .filter((timezone) => timezone.countryCode === mappingTimezoneToCountry[values.country.replace(/\s/g, '')])
        .sort((a, b) => b.rawOffsetInMinutes - a.rawOffsetInMinutes)
        .map((timezone) => ({
          label: timezone.abbreviation + ' ' + prettifyTimezone(timezone.name),
          value: timezone.name,
        }));
    } else if (timeZonesList) {
      return timeZonesList.map((timezone) => ({
        label: timezone.abbreviation + ' ' + prettifyTimezone(timezone.name),
        value: timezone.name,
      }));
    }
  }, [values?.country, timeZonesList]);

  useMount(() => {
    if (isNewProject && !values.country) {
      resetForm({values: {...values, timezone: localTimezone}});
    }
    import('@vvo/tzdb')
      .then((module) => {
        const list = module.getTimeZones();
        setTimeZonesList(list);
      })
      .catch((e) => {
        toast.error(t('form.timezone.errors.timezones_import'));
        Sentry.captureException(e, {tags: {context: 'ProjectForm', name: '@vvo/tzdb'}});
      });
  });

  useOutsideClick({
    ref: tooltipRef,
    callback: () => {
      setTooltipTimeZoneVisible(false);
    },
  });

  const showTooltip = () => setTooltipTimeZoneVisible(true);

  const tooltipTimeZone = tooltipTimeZoneVisible ? (
    <div
      ref={tooltipRef}
      className="react-popper react-popper--help react-popper--with-button-close"
      style={{position: 'absolute', bottom: '100%', right: 0}}
    >
      <div className="react-popper__title">{t('tooltip.title', 'Time Zone')}</div>
      <div className="react-popper__text">
        {t(
          'tooltip.description',
          `Select the Time Zone for your Project Location. This will allow us to sync your activities start and end
        dates/times with your time zone.`,
        )}
      </div>
      <Button
        className="react-popper__button-close"
        iconOnly
        icon={
          <Icon
            name={'clear'}
            onClick={() => setTooltipTimeZoneVisible(false)}
            className="ctrl-btn-clear__icon"
            colorFill
            size={24}
          />
        }
      >
        <span className="ctrl-btn-clear__text">{t('tooltip.buttons.close_help', 'Close help')}</span>
      </Button>
    </div>
  ) : null;

  return (
    <>
      <Form className="form-default" style={{position: 'relative'}}>
        <div className="form-default__container">
          <header className="form-default__header">
            <h2 className="form-default__title">
              {isNewProject ? t('form.title.new', 'New Project') : t('form.title.edit', 'Edit Project')}
            </h2>
            {!isNewProject && (
              <Permission roles={['company_admin']}>
                <div className="form-default__actions">
                  <button
                    data-cy="btnDeleteProject"
                    className="z-ctrl-btn z-ctrl-btn--color-second form-default__actions-button"
                    type="button"
                    onClick={onDelete}
                    disabled={disabled || loading}
                  >
                    <Icon className="z-ctrl-btn__icon" colorFill size={24} name="remove_from_trash" />
                    <span className="z-ctrl-btn__text">{t('buttons.delete', 'Delete Project')}</span>
                  </button>
                </div>
              </Permission>
            )}
          </header>
          <div className="form-default__grid">
            <div className="form-default__item form-default__item--full">
              <FormControl name="name" label={t('form.name.label', 'Project Name')}>
                <SkeletonFieldPreloader when={loading}>
                  <Field
                    type="text"
                    className="ctrl-textfield"
                    name="name"
                    disabled={disabled}
                    innerRef={projectNameInputRef}
                  />
                </SkeletonFieldPreloader>
              </FormControl>
            </div>
            <div className="form-default__item form-default__item--full">
              <div className="ctrl-location-full">
                <header className="ctrl-location-full__header">
                  <h3 className="ctrl-location-full__title">{t('form.location.title', 'Project Location')}</h3>
                  <div className="ctrl-location-full__description">
                    {t('form.location.description', ' We use location to set the proper timezone for your activities')}
                  </div>
                </header>
                <div className="ctrl-location-full__body">
                  <div className="ctrl-location-full__item">
                    <FormControl name="country" label={t('form.country.label', 'Country')}>
                      <SkeletonFieldPreloader when={loading}>
                        <Field name="country">
                          {({field, form}: FieldProps) => (
                            <CoreSelectField
                              {...field}
                              isClearable
                              isDisabled={disabled}
                              options={getCountryOptions(t)}
                              placeholder={t('form.country.placeholder', 'Select Country')}
                              onChange={(value) =>
                                mixpanel.trackWithAction(
                                  () =>
                                    form.setValues({
                                      ...values,
                                      [field.name]: value,
                                      state: '',
                                      city: '',
                                      timezone: values.country !== value ? '' : values.timezone,
                                    }),
                                  mixpanelEvents.selectCountry,
                                  Object.assign({}, mixpanelMeta, {Country: value}),
                                )
                              }
                            />
                          )}
                        </Field>
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                  <div className="ctrl-location-full__item">
                    <FormControl name="state" label={t('form.state.label', 'State')}>
                      <SkeletonFieldPreloader when={loading}>
                        <Geocoder
                          onChanged={(item) => {
                            setValues({
                              ...values,
                              state: item,
                              city: '',
                              timezone: '',
                            });
                          }}
                          placeholder={t('form.state.placeholder', 'Enter State')}
                          mapboxQueryTypes={['region']}
                          formatItem={(item) => item.text}
                          value={values.state}
                          hideOnSelect={true}
                          disableInput={disabled || !values.country || values.country === 'Singapore'}
                          allowCustom={false}
                          selectedCountry={values.country}
                          limit={3}
                        />
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                  <div className="ctrl-location-full__item">
                    <FormControl name="city" label={t('form.city.label', 'City')}>
                      <SkeletonFieldPreloader when={loading}>
                        <Geocoder
                          onChanged={(item, longitude, latitude) => {
                            setFieldValue('city', item);
                            item && longitude && latitude && getTimezoneByCoordinates(longitude, latitude);
                          }}
                          placeholder={t('form.city.placeholder', 'Enter City')}
                          mapboxQueryTypes={['place']}
                          formatItem={(item) => item.text}
                          value={values.city}
                          disableInput={disabled || !values.country || !values.state || values.country === 'Singapore'}
                          hideOnSelect={true}
                          allowCustom={false}
                          selectedCountry={values.country}
                          selectedState={values.state}
                          limit={3}
                        />
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                  <div className="ctrl-location-full__item">
                    <FormControl
                      name="timezone"
                      label={t('form.timezone.label', 'Timezone')}
                      iconHelpName={'help'}
                      onHelpClick={showTooltip}
                      tooltip={tooltipTimeZone}
                    >
                      <SkeletonFieldPreloader when={loading}>
                        <Field name="timezone">
                          {({field, form}: FieldProps) => (
                            <CoreSelectField
                              {...field}
                              options={timezonesOptions}
                              isSearchable
                              isDisabled={disabled}
                              maxMenuHeight={220}
                              placeholder={t('form.timezone.placeholder', 'Select Timezone')}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                                mixpanel.track(
                                  mixpanelEvents.selectTimezone,
                                  Object.assign({}, mixpanelMeta, {Timezone: value}),
                                );
                              }}
                            />
                          )}
                        </Field>
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-default__item form-default__item--full">
              <div className="ctrl-location-full">
                <header className="ctrl-location-full__header">
                  <h3 className="ctrl-location-full__title">{t('form.hours.title', 'Project Working Hours')}</h3>
                </header>
                <div className="ctrl-location-full__body">
                  <div className="form-default__item">
                    <FormControl
                      className="ctrl-form--button"
                      name="defaultTaskStart"
                      label={t('form.start_time.label', 'Start Time')}
                    >
                      <SkeletonFieldPreloader when={loading}>
                        <Field name="defaultTaskStart">
                          {({field: {value, onChange, ...fieldProps}, form: {setFieldValue}}: FieldProps) => (
                            <TimePicker
                              onChange={(value) => setFieldValue(fieldProps.name, value)}
                              selected={value}
                              disabled={disabled}
                              {...fieldProps}
                            />
                          )}
                        </Field>
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                  <div className="form-default__item" style={{position: 'relative'}}>
                    <FormControl
                      className="ctrl-form--button"
                      name="defaultTaskEnd"
                      label={t('form.end_time.label', 'End Time')}
                    >
                      <SkeletonFieldPreloader when={loading}>
                        <Field name="defaultTaskEnd">
                          {({field: {value, onChange, ...fieldProps}, form: {setFieldValue}}: FieldProps) => (
                            <TimePicker
                              onChange={(value) => setFieldValue(fieldProps.name, value)}
                              selected={value}
                              disabled={disabled}
                              {...fieldProps}
                            />
                          )}
                        </Field>
                      </SkeletonFieldPreloader>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            {!isNewProject && (
              <div className="form-default__item form-default__item--full">
                <div className="ctrl-location-full">
                  <header className="ctrl-location-full__header">
                    <h3 className="ctrl-location-full__title">Project Details</h3>
                  </header>
                  <div className="ctrl-location-full__body">
                    <div className="form-default__item">
                      <FormControl
                        className="ctrl-form--button"
                        name="ownerCompanyGroupMappingId"
                        label={t('form.owner.label', 'Owner')}
                      >
                        <SkeletonFieldPreloader when={loading}>
                          <Field name="ownerCompanyGroupMappingId">
                            {({field}: FieldProps) => (
                              <AsyncProjectSubcontractorSelect
                                {...field}
                                _projectId={id}
                                isCreatable
                                isDisabled={disabled}
                                onChange={(value) => onSelectCompany(field.name, value)}
                                onAfterCreateOption={(company) => onSelectCompany(field.name, company)}
                                placeholder={t('form.owner.placeholder', 'Select Owner')}
                              />
                            )}
                          </Field>
                        </SkeletonFieldPreloader>
                      </FormControl>
                    </div>
                    <div className="form-default__item" style={{position: 'relative'}}>
                      <FormControl
                        className="ctrl-form--button"
                        name="architectCompanyGroupMappingId"
                        label={t('form.architect.label', 'Architect of Record')}
                      >
                        <SkeletonFieldPreloader when={loading}>
                          <Field name="architectCompanyGroupMappingId">
                            {({field}: FieldProps) => (
                              <AsyncProjectSubcontractorSelect
                                {...field}
                                _projectId={id}
                                isCreatable
                                isDisabled={disabled}
                                onChange={(value) => onSelectCompany(field.name, value)}
                                onAfterCreateOption={(company) => onSelectCompany(field.name, company)}
                                placeholder={t('form.engineer.label', 'Select Engineer')}
                              />
                            )}
                          </Field>
                        </SkeletonFieldPreloader>
                      </FormControl>
                    </div>
                    <div className="form-default__item">
                      <FormControl
                        className="ctrl-form--button"
                        name="engineerCompanyGroupMappingId"
                        label={t('form.engineer.label', 'Engineer of Record')}
                      >
                        <SkeletonFieldPreloader when={loading}>
                          <Field name="engineerCompanyGroupMappingId">
                            {({field}: FieldProps) => (
                              <AsyncProjectSubcontractorSelect
                                {...field}
                                _projectId={id}
                                isCreatable
                                isDisabled={disabled}
                                onChange={(value) => onSelectCompany(field.name, value)}
                                onAfterCreateOption={(company) => onSelectCompany(field.name, company)}
                                placeholder={t('form.engineer.label', 'Select Engineer')}
                              />
                            )}
                          </Field>
                        </SkeletonFieldPreloader>
                      </FormControl>
                    </div>
                    <div className="form-default__item" style={{position: 'relative'}}>
                      <FormControl
                        className="ctrl-form--button"
                        name="subcontractorCompanyGroupMappingId"
                        label={t('form.subcontractor.label', 'Subcontractor of Record')}
                      >
                        <SkeletonFieldPreloader when={loading}>
                          <Field name="subcontractorCompanyGroupMappingId">
                            {({field}: FieldProps) => (
                              <AsyncProjectSubcontractorSelect
                                {...field}
                                _projectId={id}
                                isCreatable
                                isDisabled={disabled}
                                onChange={(value) => onSelectCompany(field.name, value)}
                                onAfterCreateOption={(company) => onSelectCompany(field.name, company)}
                                placeholder={t('form.subcontractor.placeholder', 'Select Subcontractor')}
                              />
                            )}
                          </Field>
                        </SkeletonFieldPreloader>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};
export default ProjectForm;
