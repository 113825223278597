import {AxiosResponse} from 'axios';

import {TasksApi} from 'api';
import {Activity, LoadOptions} from 'modules/Tasks/components/Gantt/types';
import {transformIssuesData, transformLookaheadData} from 'modules/Tasks/components/Gantt/utils/functions';
import {OSKMap} from 'modules/Tasks/components/Gantt/utils/gantt';
import {SortOrder} from 'shared/constants/common';
import {fetchAllGenerator, fetchAllWithGenerator} from 'shared/helpers/axios';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {IssueModelRawDTO, TaskListMinimalModel, TaskModelRawDTO, TaskProjection} from 'shared/models/task';

export async function loadActivityIds({queryParams, projectId, dataRange, done, setLoading}: LoadOptions) {
  setLoading(true);

  try {
    const results: TaskListMinimalModel[] = [];

    for await (const batch of fetchAllGenerator<TaskListMinimalModel>({
      request: async (offset: number, take: number) => {
        return TasksApi.getProjectTasks({
          projection: TaskProjection.taskMinimal,
          offset: offset,
          limit: take,
          sortField: 'outline_sort_key',
          sortOrder: SortOrder.ASC,
          includeSummaryTasks: true,
          params: addStatusToFilters(queryParams.state, {
            ...queryParams,
            objectTypeList: queryParams.milestonesOnly ? ['milestone'] : ['task', 'summary', 'activity', 'milestone'],
            projectId,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          }),
        });
      },
      initialTake: 1000,
    })) {
      results.push(...batch);
    }

    const activityIds = results.map((task) => task.id);
    done(activityIds);
    return results;
  } catch (error) {
    console.error('Error loading activity IDs:', error);
    throw error;
  } finally {
    setLoading(false);
  }
}

export async function loadActivities({collapsed, queryParams, projectId, dataRange, done, setLoading}: LoadOptions) {
  const oskMap = new Map() as OSKMap;
  const collapsedSet = new Set(collapsed || []);

  const handleResponse = (data: TaskModelRawDTO[]) => {
    const ganttData = transformLookaheadData({
      tasks: data,
      projectId,
      flatList: false,
      oskMap,
      collapsed: collapsedSet,
    });
    done(ganttData);
  };

  const allTasks: TaskModelRawDTO[] = [];

  try {
    for await (const batch of fetchAllGenerator<TaskModelRawDTO>({
      request: (offset, take) =>
        TasksApi.getProjectTasks({
          projection: TaskProjection.task,
          offset: offset,
          limit: take,
          sortField: 'outline_sort_key',
          sortOrder: SortOrder.ASC,
          includeSummaryTasks: true,
          params: addStatusToFilters(queryParams.state, {
            ...queryParams,
            objectTypeList: queryParams.milestonesOnly ? ['milestone'] : ['task', 'summary', 'activity', 'milestone'],
            projectId,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          }),
        }) as Promise<AxiosResponse<TaskModelRawDTO[]>>,
      initialTake: 500,
    })) {
      allTasks.push(...batch);
      handleResponse(allTasks);

      if (allTasks.length === batch.length) {
        // This is the first batch
        setLoading(false);
      }
    }

    return allTasks;
  } catch (error) {
    console.error('Error loading activities:', error);
    throw error;
  } finally {
    setLoading(false);
  }
}

export async function loadIssues({queryParams, projectId, dataRange, done, setLoading}: LoadOptions) {
  setLoading(true);

  try {
    const allIssues: IssueModelRawDTO[] = [];

    for await (const batch of fetchAllGenerator<IssueModelRawDTO>({
      request: async (offset: number, take: number) => {
        return TasksApi.getTaskIssues(projectId, {
          rangeParams: {
            offset: offset,
            limit: take,
          },
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
            schedIntersect: dataRange?.map((date) => date.toISOString()) as [string, string],
          },
        });
      },
      initialTake: 500,
    })) {
      allIssues.push(...batch);
    }

    const ganttData = transformIssuesData({
      tasks: allIssues,
      projectId,
      flatList: false,
    });

    const allTaskIds = new Set<string>();
    ganttData.tasks.forEach((issue) => issue.task_ids.forEach((taskId) => allTaskIds.add(taskId)));

    const tasksDetails = await fetchAllWithGenerator({
      request: async (offset: number, take: number) => {
        const taskIds = Array.from(allTaskIds).slice(offset, offset + take);
        const tasks = await TasksApi.getTasks(taskIds);
        return {
          data: tasks,
          headers: {'x-total-count': allTaskIds.size.toString()},
          status: 200,
          statusText: 'OK',
          config: {},
        };
      },
      initialTake: 100,
    });

    const taskIdToNameMap = new Map<string, string>();
    tasksDetails.forEach((task) => taskIdToNameMap.set(task.id, task.name));

    const tasks = ganttData.tasks.map((issue) => ({
      ...issue,
      activities: issue.task_ids.reduce((acc, taskId) => {
        const taskName = taskIdToNameMap.get(taskId);
        if (taskName) {
          acc.push({id: taskId, name: taskName});
        }
        return acc;
      }, [] as Activity[]),
    }));

    done({links: ganttData.links, tasks});
    return allIssues;
  } catch (error) {
    console.error('Error loading issues:', error);
    throw error;
  } finally {
    setLoading(false);
  }
}
